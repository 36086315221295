<template>
    <div>
        <Toolbar
          :handle-delete="del"
          :handle-reset="resetForm"
          :handle-submit="onSendForm"
        />
        <BackendGroupForm
          :errors="violations"
          :values="item"
          ref="updateForm"
          v-if="item"
        />
        <Loading :visible="isLoading || deleteLoading"/>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { mapFields } from 'vuex-map-fields';
  import BackendGroupForm from '../../components/backendgroup/Form.vue';
  import Loading from '../../components/Loading';
  import Toolbar from '../../components/Toolbar';
  import UpdateMixin from '../../mixins/UpdateMixin';

  const servicePrefix = 'BackendGroup';

  export default {
    name: 'BackendGroupUpdate',
    servicePrefix,
    mixins: [UpdateMixin],
    components: {
      Loading,
      Toolbar,
      BackendGroupForm
    },
    computed: {
      ...mapFields('backendGroup', {
        deleteLoading: 'isLoading',
        isLoading: 'isLoading',
        error: 'error',
        updated: 'updated',
        violations: 'violations'
      }),
      ...mapGetters('backendGroup', ['find'])
    },
    methods: {
      ...mapActions('backendGroup', {
        deleteItem: 'del',
        retrieve: 'load',
        update: 'update',
      })
    }
  };
</script>
